import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/the-peanut-butter-solution-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1985 children's movie, The Peanut Butter Solution"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 18</h1>
                    <h2>The Peanut Butter Solution</h2>
                    <h3>September 22, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Michael Rubbo // <b>Starring:</b> Michael Hogan &amp; Mathew Mackay</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/18-The-Peanut-Butter-Solution-e29lk25" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe! horror podcast episode 18, The Peanut Butter Solution"></iframe>
                        </div>
                        <p><Link to="/transcripts/the-peanut-butter-solution">Read the episode transcript</Link></p>
                        <p>This week Bryan and Dave close out their What The Fuck?? series with a children's movie that terrorized an entire generation in Canada, The Peanut Butter Solution. It's a tale of loss and woe when a child loses his hair due to a terrible fright and deals with it poorly. There's also some real nightmare material in there about being kidnapped by a teacher and forced to work in his child slave labor prison. Also, you'll be amazed at how many Simpsons references these guys can stuff into a single podcast.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=I2Cm4Cy3rxs" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=I2Cm4Cy3rxs</OutboundLink>⁠</p>
                        <p><OutboundLink href="⁠https://www.youtube.com/watch?v=HjHiosqcPbs" target="_blank" rel="noreferrer">Listen to the Magic Man by Celine Dion</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/dont-panic">Next Episode</Link></li>
                        <li><Link to="/episodes/burnt-offerings">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)